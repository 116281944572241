<template>
    <div class="about faq">
        <div class="page-head about">
            <h1 class="page-title font-weight-bold text-uppercase text-center">
                Frequently Asked Questions
            </h1>
        </div>
        <div class="page-content">
            <div class="container">
                <!-- <div class="row align-items-center">
                    <div class="col-md-10 col-lg-8 mx-auto">
                        
                    </div>
                </div> -->
                <FAQ />
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import FAQ from "@/components/FAQ";
import TopFooterAds from "@/components/TopFooterAds";
export default {
    name: "FrequentlyAskedQuestions",
    components: {
        FAQ,
        TopFooterAds,
    },
};
</script>

<style lang="scss" scoped>
h1.page-title {
    font-size: 4rem;
    @media screen and (max-width: 580px) {
        font-size: 3rem;
    }
}
</style>
